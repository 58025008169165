<script setup lang="ts">
import {onClickOutside, useFocus, useMagicKeys} from "@vueuse/core";
import useCustomSearchVisibility from "~/composables/useCustomSearchVisibility";
import SearchSuggestions from "~/components/layout/SearchSuggestions.vue";

const {isCustomSearchVisible} = useCustomSearchVisibility();

const props = defineProps<{
    type?: string;
}>();

const emits = defineEmits<{
    (e: "click-away"): void;
}>();

// True when the search bar is active and user can type in the search field
const active = inject("searchActive", ref(false));

// Reference to the search container
const searchContainer = ref(null);
const searchInput = ref();
const typingQuery = inject('searchQuery');

const hideSearch = computed(() => {
    return props.type !== 'mobile' && isCustomSearchVisible();
})

watch(active, (value) => {
    const {focused} = useFocus(searchInput);

    focused.value = value;
});

const showSuggest = computed(() => {
    return typingQuery.value.length >= 1 && active.value;
});

if (process.client) {
    onClickOutside(searchContainer, () => {
        active.value = false;
        emits("click-away");
    });
}

const {enter} = useMagicKeys({target: searchInput});

const isSideMenuOpened = inject("isSideMenuOpened", ref(false));

watch(enter, (value) => {
    if (!value) return;

    isSideMenuOpened.value = false;

    active.value = false;
    navigateTo("/search?query=" + typingQuery.value);
});

</script>

<template>
<div
    ref="searchContainer"
    class="relative group lg:pr-0 transition duration-300 inline-block w-full box-border"
    :class="{'hidden': hideSearch}"
>
    <div class="relative inline-block w-full">
        <input
            ref="searchInput"
            id="mainSearchInput"
            v-model="typingQuery"
            data-test-id="layout-search-input"
            autocomplete="off"
            type="search"
            :class="{
                'bg-white text-[16px]': props.type && props.type == 'mobile',
                'bg-brand-lightGray rounded-full': !props.type
            }"
            class="border-transparent h-[35px] font-light px-6 py-1 outline-none box-border w-full text-xs"
            :placeholder="$t('search.searchPlaceholder')"
            @click="active = true"
        />
        <NuxtImg
            loading="lazy"
            src="/search.svg"
            height="15"
            width="15"
            alt="Søg"
            class="absolute hidden md:block right-3 lg:right-6 top-1/2 -translate-y-1/2 pointer-events-none"
        />
    </div>
    <div
        v-if="showSuggest"
        id="searchSuggestItems"
        class="absolute bg-white border-gray-100 border-t-1 left-0 duration-300 mt-2 overflow-hidden shadow-md transition-height w-full md:mt-4 z-10"
    >
        <SearchSuggestions :query="typingQuery"/>
    </div>
</div>
</template>
<style>

#mainSearchInput::-webkit-search-decoration,
#mainSearchInput::-webkit-search-cancel-button,
#mainSearchInput::-webkit-search-results-button,
#mainSearchInput::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

@media screen and (max-width: 767px) {
    #mainSearchInput::placeholder {
        font-size: 13px
    }
    #searchSuggestItems {
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        max-height: calc(100vh - 100px);
        overflow-y: auto;
    }
}

#mainSearchInput::placeholder {
    color: #A8A8A8;
}
</style>
